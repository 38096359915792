/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import "./layout/layout.css"

function SEO({
  description,
  lang,
  meta,
  title,
  titleTemplate,
  img,
  path,
  date,
  article,
  noindex
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const blogJSONLD = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://carreiradentalclinic.pt${path}`,
    },
    headline: `${title}`,
    description: `${description}`,
    image: `https://carreiradentalclinic.pt${img}`,
    author: {
      "@type": "Organization",
      name: "Carreira Dental Clinic",
    },
    publisher: {
      "@type": "Organization",
      name: "Carreira Dental Clinic",
      logo: {
        "@type": "ImageObject",
        url: "https://carreiradentalclinic.pt/assets/favico.jpg",
      },
    },
    datePublished: `${date}`,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={
        titleTemplate ? titleTemplate : `%s | ${site.siteMetadata.title}`
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: img,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: noindex ? "noindex" : "all",
        },
      ].concat(meta)}
    >
      {article && (
        <script type="application/ld+json">{JSON.stringify(blogJSONLD)}</script>
      )}
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/react-modal/3.14.3/react-modal.min.js"
        integrity="sha512-MY2jfK3DBnVzdS2V8MXo5lRtr0mNRroUI9hoLVv2/yL3vrJTam3VzASuKQ96fLEpyYIT4a8o7YgtUs5lPjiLVQ=="
        crossorigin="anonymous"
        referrerpolicy="no-referrer"
      ></script>

      {/*<!-- Google tag (gtag.js) -->*/}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-J3RY1E42NG"
      ></script>

      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('set', 'ads_data_redaction', true)
gtag('config', 'G-J3RY1E42NG');`}
      </script>

      {/*<!-- COOKIES -->*/}
      <script
        id="cookieyes"
        type="text/javascript"
        src="https://cdn-cookieyes.com/client_data/31548b8955e8b769602d17c9/script.js"
      ></script>

      <script>{`window ['gtag_enable_tcf_support'] = true;`}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `pt`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  article: PropTypes.bool,
}

export default SEO
